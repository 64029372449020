import * as yup from "yup";

import {
  emailValidationRegex,
  haveCharacterAndNumberPasswordValidationRegex,
  onlyTextRegex,
  strongPasswordValidationRegex,
} from "config";

export const emailSchema = (
  requireMessage: string = "emailRequired",
  emailMessage: string = "emailInValid",
  matchEmailRegexMessage: string = "emailInValid"
) => {
  return yup
    .string()
    .trim()
    .required(requireMessage)
    .email(emailMessage)
    .matches(emailValidationRegex, matchEmailRegexMessage);
};

export const requireTextSchema = (
  requireMessage: string = "passwordRequired"
) => {
  return yup.string().trim().required(requireMessage);
};

/**
 * at least one lowercase letter, one uppercase letter, one special character, and one digit.
 */
export const strongPasswordSchema = (
  requireMessage: string = "passwordRequired",
  minMessage: string = "passwordLengthInvalid",
  maxMessage: string = "passwordLengthInvalid",
  matchMessage: string = "passwordFormatInvalid"
) => {
  return yup
    .string()
    .trim()
    .required(requireMessage)
    .min(8, minMessage)
    .max(32, maxMessage)
    .matches(strongPasswordValidationRegex, matchMessage);
};

/**
 * at least one letter and one digit.
 */
export const haveCharacterAndNumberPasswordSchema = (
  requireMessage: string = "passwordRequired",
  minMessage: string = "passwordLengthInvalid",
  maxMessage: string = "passwordLengthInvalid",
  matchMessage: string = "passwordFormatInvalid"
) => {
  return yup
    .string()
    .trim()
    .required(requireMessage)
    .min(8, minMessage)
    .max(32, maxMessage)
    .matches(haveCharacterAndNumberPasswordValidationRegex, matchMessage);
};

export const confirmPasswordSchema = (
  ref: string[] = ["password"],
  requireMessage: string = "confirmPasswordIsRequired",
  refMessage: string = "confirmPasswordNotMatch"
) => {
  return yup
    .string()
    .required(requireMessage)
    .oneOf(
      ref.map((r) => yup.ref(r)),
      refMessage
    );
};

export const name = (
  requireMessage: string = "lastNameRequired",
  length: number = 50,
  lengthMessage: string = "maxCharacters",
  onlyTextMessage: string = "onlyHaveText"
) => {
  return yup
    .string()
    .trim()
    .required(require)
    .max(length, lengthMessage)
    .matches(onlyTextRegex, onlyTextMessage);
};
