type Props = {
  title: string;
  className?: string;
  onClick?: () => void;
  icon?: any;
};

export const Button = (props: Props) => {
  const { title, className = "", onClick, icon } = props;

  return (
    <button
      onClick={onClick}
      className={
        "linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 " +
        className
      }
    >
      <div className="flex items-center justify-center">
        {icon || ""}
        {title}
      </div>
    </button>
  );
};
