import { ITableColumn } from "interfaces";
import { IFowarderData } from "interfaces/fowarder.interface";
import { IoEyeOutline } from "react-icons/io5";

export const dataFowarder = [
  {
    no: "1",
    name: "Hung dz",
    companyName: "Cong ty ADAMO",
    email: "hungshidro@gmail.com",
    status: "active",
  },
  {
    no: "1",
    name: "Hung dz",
    companyName: "Cong ty ADAMO",
    email: "hungshidro@gmail.com",
    status: "active",
  },
  {
    no: "1",
    name: "Hung dz",
    companyName: "Cong ty ADAMO",
    email: "hungshidro@gmail.com",
    status: "active",
  },
  {
    no: "1",
    name: "Hung dz",
    companyName: "Cong ty ADAMO",
    email: "hungshidro@gmail.com",
    status: "active",
  },
  {
    no: "1",
    name: "Hung dz",
    companyName: "Cong ty ADAMO",
    email: "hungshidro@gmail.com",
    status: "active",
  },
];

export const colums: ITableColumn<IFowarderData>[] = [
  {
    key: "no",
    title: "No",
  },
  {
    key: "name",
    title: "No",
  },
  {
    key: "companyName",
    title: "No",
  },
  {
    key: "email",
    title: "No",
  },
  {
    key: "status",
    title: "No",
  },
  {
    key: "none",
    title: "No",
    render: (val) => <IoEyeOutline />,
  },
];
