// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getMessaging, onMessage, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCBOzOBoJ1HWcO39Zcuai0lbIMYC-nYMPQ",
  authDomain: "bidlog-ed908.firebaseapp.com",
  projectId: "bidlog-ed908",
  storageBucket: "bidlog-ed908.appspot.com",
  messagingSenderId: "589699920006",
  appId: "1:589699920006:web:a0dd12ca2c99ad91836107",
  measurementId: "G-6XCZ009SPE",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

getToken(messaging, {
  vapidKey:
    "BHbnwaeyatj24RjFqE73R9ZY0Xucjgiu7eB9UFxdRmVqeYizSskzGTZN7QbuGxx14x-E4YqgG2b6G8pC2Oe3vpQ",
})
  .then((currentToken) => {
    if (currentToken) {
      console.log(currentToken);
      onMessage(messaging, (payload) => {
        console.log("Receive Message: ", payload);
      });
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // ...
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
    // ...
  });
