import avatarIcon from "assets/img/profile/avatar.png";

const UserInfo = () => {
  const userInfo = {
    firstName: "Jimmy",
    lastName: "Jimmy",
    gender: "Jimmy",
    dob: "Jimmy",
    phoneNumber: "Jimmy",
    email: "jimmysco283@gmail",
  };

  type UserInfoKey =
    | "firstName"
    | "lastName"
    | "gender"
    | "dob"
    | "phoneNumber"
    | "email";

  return (
    <div className="col-span-2">
      <div className="mb-3">
        <img src={avatarIcon} alt="" />
      </div>
      {Object.keys(userInfo).map((key, index) => {
        return (
          <div className="mt-5 flex items-center justify-between" key={index}>
            <div className="text-left">
              <div className="font-bold">{key}</div>
              <div>{userInfo["firstName"]}</div>
              <div>{userInfo[key as UserInfoKey]}</div>
              <div className="h-0.5 w-32 bg-[#CCCED3]	"></div>
              <div></div>
            </div>
            <div className="text-left">
              <div className="underline">Edit</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserInfo;
