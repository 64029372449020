type Props = {
  page: any;
  onClick?: () => void;
  active?: boolean;
};

export const Page = (props: Props) => {
  const { page, onClick, active } = props;

  return (
    <button
      onClick={onClick}
      className={`flex h-8 w-8 items-center justify-center hover:bg-gray-200 active:bg-gray-300 ${
        active && "border-[1px] border-gray-500"
      }`}
    >
      {page}
    </button>
  );
};
