import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";

// Auth Imports
import SignIn from "views/auth/SignIn";
import ForgotPassword from "views/auth/ForgotPassword";
import VerificationCode from "views/auth/VerificationCode";
import ResetPassword from "views/auth/ResetPassword";
import ResetPasswordSuccess from "views/auth/ResetPasswordSuccess";

// Profile Imports
import EditPassword from "views/profile/EditPassword";

// Icon Imports
import {
  MdBarChart,
  MdOutlineDashboard,
  MdAttachMoney,
  MdMailOutline,
} from "react-icons/md";
import UserInfo from "views/profile/UserInfo";
import {
  IoBarChartOutline,
  IoNewspaperOutline,
  IoNotificationsOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { Manufacturer } from "views/admin/manufacture";
import { Forwarder } from "views/admin/forwarder";
import { Invoice } from "views/admin/invoice";
import { StaticArticle } from "views/admin/staticArticle";
import { Notification } from "views/admin/notification";

export const routes = [
  {
    name: "Dash board",
    layout: "/admin",
    path: "default",
    component: <MainDashboard />,
  },
  {
    name: "Manufacturer",
    layout: "/admin",
    path: "manufacturer",
    component: <Manufacturer />,
    secondary: true,
  },
  {
    name: "Forwarder",
    layout: "/admin",
    path: "forwarder",
    icon: <MdBarChart className="h-6 w-6" />,
    component: <Forwarder />,
  },
  {
    name: "Invoice",
    layout: "/admin",
    path: "invoice",
    component: <Invoice />,
  },
  {
    name: "Static article",
    layout: "/admin",
    path: "static-article",
    component: <StaticArticle />,
  },
  {
    name: "Notification",
    layout: "/admin",
    path: "notification",
    component: <Notification />,
  },
  {
    name: "Contact us",
    layout: "/admin",
    path: "contact-us",
    component: <Invoice />,
  },
];

export const authRoutes = [
  {
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
  },
  {
    layout: "/auth",
    path: "forgot-password",
    component: <ForgotPassword />,
  },
  {
    layout: "/auth",
    path: "verification",
    component: <VerificationCode />,
  },
  {
    layout: "/auth",
    path: "reset-password",
    component: <ResetPassword />,
  },
  {
    layout: "/auth",
    path: "reset-password-success",
    component: <ResetPasswordSuccess />,
  },
];

//SIBE BAR ROUTES
export const sideBarRoutes: RoutesType[] = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <IoNewspaperOutline className="h-6 w-6" />,
  },
  {
    name: "Manufacturer",
    layout: "/admin",
    path: "manufacturer",
    icon: <MdOutlineDashboard className="h-6 w-6" />,
    secondary: true,
  },
  {
    name: "Forwarder",
    layout: "/admin",
    icon: <IoBarChartOutline className="h-6 w-6" />,
    path: "forwarder",
  },
  {
    name: "Invoice",
    layout: "/admin",
    path: "invoice",
    icon: <MdAttachMoney className="h-6 w-6" />,
  },
  {
    name: "Static article",
    layout: "/admin",
    path: "static-article",
    icon: <IoSettingsOutline className="h-6 w-6" />,
  },
  {
    name: "Notification",
    layout: "/admin",
    path: "notification",
    icon: <IoNotificationsOutline className="h-6 w-6" />,
  },
  {
    name: "Contact us",
    layout: "/admin",
    path: "contact-us",
    icon: <MdMailOutline className="h-6 w-6" />,
  },
];

export const profileRoutes = [
  // Profile route
  {
    name: "Password",
    layout: "/profile",
    path: "/user-info",
    component: <UserInfo />,
  },
  {
    name: "Password",
    layout: "/profile",
    path: "/password",
    component: <EditPassword />,
  },
];
