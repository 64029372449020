import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { Page } from "./Page";

type Props = {
  total?: string;
  active: number;
  onChangePage?: (page: number) => void;
};

export const Pagination = (props: Props) => {
  const { total, active } = props;

  const arr = [1, 2, 3, 4, 5, 6, 7];

  return (
    <div className="mt-6 flex w-full items-end justify-between px-10">
      <p>{total}</p>
      <div className="flex items-center">
        <>
          <Page
            key={347539745}
            page={<IoChevronBackOutline className="h-3 w-3" />}
          />
          {arr.map((page, index) => (
            <Page page={page} key={page} active={page === active} />
          ))}
          <Page
            key={8764375}
            page={<IoChevronForwardOutline className="h-3 w-3" />}
          />
        </>
      </div>
    </div>
  );
};
