import { Routes, Route, Navigate } from "react-router-dom";
import { getMessaging } from "firebase/messaging";
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ProfileLayout from "layouts/profile";
import { useEffect } from "react";
import { app } from "./firebase/firebaseInit";
import { ChakraProvider } from "@chakra-ui/react";
const App = () => {
  useEffect(() => {
    getMessaging(app);
  }, []);
  return (
    <ChakraProvider>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="profile/*" element={<ProfileLayout />} />
        <Route path="rtl/*" element={<RtlLayout />} />
        <Route path="/" element={<Navigate to="/auth" replace />} />
      </Routes>
    </ChakraProvider>
  );
};
export default App;
