import { Button } from "components/button/Button";
import InputField from "components/fields/InputField";
import { Pagination } from "components/pagination/Pagination";
import { TableLayout } from "components/table/TaybleLayout";
import { TiPlus } from "react-icons/ti";
import { colums, dataFowarder } from "./mock";

export const Forwarder = () => {
  return (
    <div className="h-full w-full">
      <div className="mb-4 flex w-full flex-row items-end justify-between">
        <InputField
          id="Search"
          placeholder="Search"
          className="min-w-[360px] max-w-[30%] border-gray-600"
        />
        <Button
          title="Add new"
          className="h-[44px] max-w-[140px]"
          icon={<TiPlus className="mr-1" />}
        />
      </div>
      <TableLayout columns={colums} data={dataFowarder} />
      <Pagination total="1000" active={4} />
    </div>
  );
};
