//path Admin
export const PATH_ADMIN = "/admin";

//PATH Auth
export const PATH_FORGOT_PASSWORD = "/auth/forgot-password";
export const PATH_SIGN_IN = "/auth/sign-in";
export const PATH_VERIFICATION_CODE = "/auth/verification";
export const PATH_RESET_PASSWORD = "/auth/reset-password";
export const PATH_RESET_PASSWORD_SUCCESS = "/auth/reset-password-success";

//path Profile setting
export const PATH_USER_INFO = "/profile/user-info";
export const PATH_CHANGE_PASSWORD = "/profile/password";

