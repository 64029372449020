import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { InputController } from "components/controller/InputController";
import { useState } from "react";
import { useForm } from "react-hook-form";

interface PropsModal {
  title: String;
}
const defaultValues = {
  name: "",
  surname: "",
  phone: "",
  email: "",
  companyName: "",
  vatId: "",
  address: "",
  industry: "",
  active: "",
};

export const FormModal = (props: PropsModal) => {
  const [isOpen, setOpen] = useState(true);
  const onClose = () => {
    setOpen(false);
  };

  const {
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    reValidateMode: "onChange",
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <InputController
                control={control}
                name={"name"}
                placeholder={"Name"}
                label={"Name"}
                extra="mb-3"
                error={errors.name?.message}
              />
              <InputController
                control={control}
                name={"surname"}
                placeholder={"Surname"}
                label={"Surname"}
                extra="mb-3"
                error={errors.surname?.message}
              />
              <InputController
                control={control}
                name={"phone"}
                placeholder={"Phone"}
                label={"Phone"}
                extra="mb-3"
                error={errors.phone?.message}
              />
              <InputController
                control={control}
                name={"email"}
                placeholder={"Email"}
                label={"Email"}
                extra="mb-3"
                error={errors.email?.message}
              />
              <InputController
                control={control}
                name={"companyName"}
                placeholder={"CompanyName"}
                label={"CompanyName"}
                extra="mb-3"
                error={errors.companyName?.message}
              />
              <InputController
                control={control}
                name={"vatId"}
                placeholder={"VAT - ID"}
                label={"VAT - ID"}
                extra="mb-3"
                error={errors.vatId?.message}
              />
            </div>
            <div>
              <InputController
                control={control}
                name={"address"}
                placeholder={"Address"}
                label={"Address"}
                extra="mb-3"
                error={errors.address?.message}
              />
              <InputController
                control={control}
                name={"industry"}
                placeholder={"Industry"}
                label={"Industry"}
                extra="mb-3"
                error={errors.industry?.message}
              />
              <InputController
                control={control}
                name={"active"}
                placeholder={"Active"}
                label={"Active"}
                extra="mb-3"
                error={errors.active?.message}
              />
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Add
          </Button>
          <Button variant="outline">Discard</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
