import * as yup from "yup";

import {
  confirmPasswordSchema,
  emailSchema,
  haveCharacterAndNumberPasswordSchema,
  requireTextSchema,
} from "./schema";

export const loginSchema = yup.object().shape({
  email: emailSchema(),
  password: requireTextSchema(),
});

export const forgotSchema = yup.object().shape({
  email: emailSchema(),
});

export const verificationSchema = yup.object().shape({
  code: requireTextSchema("Code Required"),
});

export const resetPasswordSchema = yup.object().shape({
  password: haveCharacterAndNumberPasswordSchema("Password Required"),
  confirmPassword: confirmPasswordSchema(),
});
