import changedPasswordImg from "assets/img/profile/changed-password.png";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PATH_ADMIN } from "navigation";
import { InputController } from "components/controller/InputController";
import { loginSchema } from "schema";
import { Button } from "components/button/Button";
import { useState } from "react";

const defaultValues = {
  email: "",
  password: "",
};

type TData = typeof defaultValues;

const EditPassword = () => {
  const [isChangePassword, setChangePassword] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(loginSchema),
    reValidateMode: "onChange",
  });

  const onSubmit = (data: TData) => {
    navigate(PATH_ADMIN);
  };
  return (
    <div className="col-span-2">
      {isChangePassword ? (
        <div className="flex h-full w-full  px-2 md:mx-0 md:px-0 lg:mb-10">
          {/* Sign in section */}
          <div className=" min-w-[30vw] max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700 dark:text-white">
              Change password
            </h4>
            <p className="mb-9 ml-1 text-center text-base text-gray-600">
              Please kindly set your new password.
            </p>
            {/* Email */}
            <InputController
              control={control}
              name={"email"}
              id={"email"}
              label={"Current password"}
              placeholder={"mail@simmmple.com"}
              extra="mb-3"
              error={errors.email?.message}
            />

            {/* Password */}
            <InputController
              control={control}
              name={"password"}
              id={"password"}
              label={"New password"}
              placeholder={"Min. 8 characters"}
              extra="mb-3"
              type={"password"}
              error={errors.password?.message}
            />

            {/* Password */}
            <InputController
              control={control}
              name={"password"}
              id={"password"}
              label={"Re-enter new password"}
              placeholder={"Min. 8 characters"}
              extra="mb-3"
              type={"password"}
              error={errors.password?.message}
            />

            <div className="flex">
              <Button
                title="Cancel"
                className="bg-transparent mx-2	"
                onClick={handleSubmit(onSubmit)}
              />
              <Button
                title="Save changes"
                className="mx-2"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className=" min-w-[30vw] max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <div className="flex justify-center">
            <img src={changedPasswordImg} alt="" />
          </div>
          <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700 dark:text-white">
            Change password
          </h4>
          <p className="mb-9 ml-1 text-center text-base text-gray-600">
            Please kindly set your new password.
          </p>
          <div className="text-center">
            <Button
              title="Back to homepage"
              className="mx-2 w-64	"
              onClick={() => setChangePassword(true)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditPassword;
