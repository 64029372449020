/* eslint-disable */

import { HiX } from "react-icons/hi";

import Links from "./components/Links";
import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import { sideBarRoutes } from "navigation/routes";
import avatar from "assets/img/avatars/avatar4.png";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          ADAMO <span className="font-medium">BASE</span>
        </div>
      </div>
      <div className="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <div className="flex items-center justify-between px-10 pb-4 pr-8">
        <img className="h-12 w-12 rounded-full" src={avatar} alt="Elon Musk" />
        <div>
          <p className="ml-3 text-right text-base font-bold text-gray-900 dark:text-white">
            Elon Musk
          </p>
          <p className="ml-3 text-right text-sm font-medium text-gray-500 dark:text-white">
            CEO of SpaceX
          </p>
        </div>
      </div>
      <ul className="mb-auto pt-1">
        <Links routes={sideBarRoutes} />
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center">
        <SidebarCard />
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
