import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { Button } from "components/button/Button";
import { PATH_RESET_PASSWORD } from "navigation";
import { verificationSchema } from "schema";
import { InputController } from "components/controller/InputController";

const defaultValues = {
  code: "",
};

type TData = typeof defaultValues;

export default function VerificationCode() {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(verificationSchema),
    reValidateMode: "onChange",
  });

  const onSubmit = (data: TData) => {
    navigate(PATH_RESET_PASSWORD);
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
      {/* Sign in section */}
      <div className="mt-[10vh] min-w-[30vw]  flex-col items-center md:max-w-[420px] md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700 dark:text-white">
          Check your email!
        </h4>
        <p className="mb-9 ml-1 text-center text-base text-gray-600">
          Please enter the 4-digit verification code that were sent to your
          email. If you don't get the email, please contact support@adamo.com
        </p>
        {/* Email */}
        <InputController
          control={control}
          name={"code"}
          id={"code"}
          label={"Enter verification code"}
          extra="mb-3"
          error={errors.code?.message}
        />

        <button className="mx-auto my-4 flex h-fit w-fit items-center hover:cursor-pointer">
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.70994 2.11997L2.82994 5.99997L6.70994 9.87997C7.09994 10.27 7.09994 10.9 6.70994 11.29C6.31994 11.68 5.68994 11.68 5.29994 11.29L0.709941 6.69997C0.319941 6.30997 0.319941 5.67997 0.709941 5.28997L5.29994 0.699971C5.68994 0.309971 6.31994 0.309971 6.70994 0.699971C7.08994 1.08997 7.09994 1.72997 6.70994 2.11997V2.11997Z"
              fill="#A3AED0"
            />
          </svg>
          <p className="ml-3 text-sm text-gray-600">Resend email</p>
        </button>
        <Button title="Confirm" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
}
