import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/table";

import { ITableColumn } from "interfaces";

type Props<T extends Record<string, any> = Record<string, any>> = {
  columns: ITableColumn<T>[];
  data: T[];
  caption?: string;
  variant?: "simple" | "striped" | "unstyled";
  showFooter?: boolean;
};

export const TableLayout = <
  T extends Record<string, any> = Record<string, any>
>(
  props: Props<T>
) => {
  const {
    columns,
    data,
    caption = "",
    variant = "simple",
    showFooter = false,
  } = props;

  const rederDefault = (value: any) => {
    return <p>{value}</p>;
  };

  const Header = () => {
    return (
      <Thead>
        <Tr>
          {columns.map((col, index) => (
            <Th className="bg-gray-200" key={index + col.key}>
              {col.renderTitle ? col.renderTitle() : col.title || ""}
            </Th>
          ))}
        </Tr>
      </Thead>
    );
  };

  const Body = () => {
    return (
      <Tbody>
        {data.map((value, index) => {
          return (
            <Tr>
              {columns.map((col, index) => {
                return (
                  <Td key={index + col.key}>
                    {col.render
                      ? col.render(value[col.key], value)
                      : rederDefault(value[col.key])}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    );
  };

  const Footer = () => {
    return (
      <Tfoot>
        <Tr>
          {columns.map((col, index) => (
            <Th key={index + col.key}>
              {col.renderTitle ? col.renderTitle() : col.title || ""}
            </Th>
          ))}
        </Tr>
      </Tfoot>
    );
  };

  return (
    <TableContainer>
      <Table variant={variant}>
        {caption && <TableCaption>{caption}</TableCaption>}
        <Header />
        <Body />
        {showFooter && <Footer />}
      </Table>
    </TableContainer>
  );
};
