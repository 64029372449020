export const StaticArticle = () => {
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-2 h-full w-full rounded-xl 2xl:col-span-2">
        <div className="mb-5 mt-5 flex items-center justify-between px-[26px]">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            StaticArticle
          </h4>
        </div>
      </div>
    </div>
  );
};
